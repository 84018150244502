<template>
	<el-scrollbar class="seller-scrollbar">
		<el-card class="seller-card">
			<el-breadcrumb slot="header" separator="/">
				<el-breadcrumb-item :to="{ name: 'customerStrategy' }">{{ $t('seller.breadcrumb.strategyData') }}</el-breadcrumb-item>
				<el-breadcrumb-item>{{ $t('seller.breadcrumb.strategyDataInfo') }}</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="page-top">
				<div class="title">{{ $t('seller.breadcrumb.strategyDataInfo') }}</div>
				<ul class="text">
					<li>{{ rategyData.title }}</li>
					<li><span>{{ $t('seller.customer.operatSceneText12') }}</span>{{ rategyData.title }}</li>
					<li><span>{{ $t('seller.screen.createTime') }}: {{ rategyData.create_time | parseTime() }}</span></li>
				</ul>
				<ul class="type">
					<li v-if="rategyData.is_recommend === 1">
						<el-tag size="mini">{{ $t('seller.pcdecorate.shop') }}</el-tag>
						<span>{{ $t('seller.customer.strategyText3') }}</span>
						<el-tag :type="rategyData.recommend_content.status | typeFilter({0:'warning',1:'success',2:'',3:'danger'})" size="mini">{{ rategyData.recommend_content.status | typeFilter($t('seller.filters.strategyItemStatus')) }}</el-tag>
						<span>{{ rategyData.recommend_content.time_from | parseTime() }} - {{ rategyData.recommend_content.time_to | parseTime() }}</span>
					</li>
					<li v-if="rategyData.is_poster === 1">
						<el-tag size="mini">{{ $t('seller.pcdecorate.shop') }}</el-tag>
						<span>{{ $t('seller.customer.strategyText2') }}</span>
						<el-tag :type="rategyData.poster_content.status | typeFilter({0:'warning',1:'success',2:'',3:'danger'})" size="mini">{{ rategyData.poster_content.status | typeFilter($t('seller.filters.strategyItemStatus')) }}</el-tag>
						<span>{{ rategyData.poster_content.time_from | parseTime() }} - {{ rategyData.poster_content.time_to | parseTime() }}</span>
					</li>
				</ul>
			</div>
			<div class="page-tabs">
				<el-radio-group v-model="activeName" size="small" @change="handleChangeType">
					<el-radio-button :label="1">{{ $t('seller.customer.strategyText4') }}<el-tag size="mini">{{ $t('seller.pcdecorate.shop') }}</el-tag></el-radio-button>
					<el-radio-button v-if="rategyData.is_recommend === 1" :label="2">{{ $t('seller.customer.strategyText3') }}<el-tag size="mini">{{ $t('seller.pcdecorate.shop') }}</el-tag></el-radio-button>
					<el-radio-button v-if="rategyData.is_poster === 1" :label="3">{{ $t('seller.customer.strategyText2') }}<el-tag size="mini">{{ $t('seller.pcdecorate.shop') }}</el-tag></el-radio-button>
				</el-radio-group>
			</div>
			<div class="page-section">
				<div class="section-head">
					<div class="title">{{ $t('seller.customer.strategyText5') }}</div>
					<ul class="tools">
						<li v-if="datetimes">
							<span>{{ $t('seller.customer.strategyText6') }}</span>
							<template v-if="datType !== 3">
								{{ datetimes[0] | parseTime('{y}-{m}-{d}') }} -
							</template>
							{{ datetimes[1] | parseTime('{y}-{m}-{d}') }}
						</li>
						<li>{{ $t('seller.customer.strategyText7') }}</li>
						<li class="tool-times">
							<el-button :type="datType === 1?'primary':''" size="small" @click="handleChangeTime(1)" round>{{ $t('seller.unit.near') + '7' + $t('seller.unit.day') }}</el-button>
							<el-button :type="datType === 2?'primary':''" size="small" @click="handleChangeTime(2)" round>{{ $t('seller.unit.near')  + '30' + $t('seller.unit.day') }}</el-button>
							<el-button :type="datType === 3?'primary':''" size="small" @click="handleChangeTime(3)" round>{{ $t('seller.unit.day') }}</el-button>
							<div style="height: 0px;width: 0;overflow:hidden">
								<el-date-picker
									v-model="dayData"
									type="date"
									ref="dayPicker"
									:placeholder="$t('sycm.placeholder.date')"
									:picker-options="pickerOptions"
									@change="changeDatePicker">
								</el-date-picker>
							</div>
						</li>
					</ul>
				</div>
				<div class="section-wrap">
					<div class="section-effect">
						<div class="effect-list" v-if="rategyData.operate_effect">
							<div class="item">
								<div class="item-name">
									<span>{{ $t('seller.customer.strategyText8') }}</span>
									<el-popover
										placement="top"
										trigger="hover">
										<p>{{ $t('seller.customer.strategyText9') }}</p>
										<p>{{ $t('seller.customer.strategyText10') }}</p>
										<i class="el-icon-warning-outline" slot="reference"></i>
									</el-popover>
								</div>
								<div class="item-text">{{ rategyData.operate_effect.predict || '-'}}</div>
							</div>
							<div class="item">
								<div class="item-name">
									<span>{{ $t('seller.customer.strategyText11') }}</span>
									<el-popover
										placement="top"
										trigger="hover">
										<p>{{ $t('seller.customer.strategyText12') }}</p>
										<p>{{ $t('seller.customer.strategyText13') }}</p>
										<i class="el-icon-warning-outline" slot="reference"></i>
									</el-popover>
								</div>
								<div class="item-text">{{ rategyData.operate_effect.touch || '-'}}</div>
							</div>
						</div>
						<div class="effect-title">
							<span class="name">{{ $t('seller.customer.strategyText14') }}</span>
							<el-popover
								placement="top"
								trigger="hover">
								<p>{{ $t('seller.customer.strategyText15') }}</p>
								<p>{{ $t('seller.customer.strategyText16') }}</p>
								<p>{{ $t('seller.customer.strategyText17') }}</p>
								<i class="el-icon-warning-outline" slot="reference"></i>
							</el-popover>
						</div>
						<div class="effect-list" v-if="rategyData.operate_effect">
							<div class="item">
								<div class="item-name">{{ $t('seller.customer.strategyText18') + $t('seller.customer.strategyText19') }}</div>
								<div class="item-text">{{ rategyData.operate_effect.visitors || '-'}}</div>
							</div>
							<div class="item">
								<div class="item-name">{{ $t('seller.customer.strategyText18') + $t('seller.customer.strategyText20') }}</div>
								<div class="item-text">{{ rategyData.operate_effect.collect || '-'}}</div>
							</div>
							<div class="item">
								<div class="item-name">{{ $t('seller.customer.strategyText18') + $t('seller.customer.strategyText21') }}</div>
								<div class="item-text">{{ rategyData.operate_effect.add_cart || '-'}}</div>
							</div>
							<div class="item">
								<div class="item-name">{{ $t('seller.customer.strategyText18') + $t('seller.customer.strategyText22') }}</div>
								<div class="item-text">{{ rategyData.operate_effect.buyers || '-'}}</div>
							</div>
							<div class="item">
								<div class="item-name">{{ $t('seller.customer.strategyText18') + $t('seller.customer.strategyText23') }}</div>
								<div class="item-text">{{ rategyData.operate_effect.prices || '-'}}</div>
							</div>
							<div class="item">
								<div class="item-name">{{ $t('seller.customer.strategyText18') + $t('seller.customer.strategyText24') }}</div>
								<div class="item-text">{{ rategyData.operate_effect.pay_buyers || '-'}}</div>
							</div>
							<div class="item">
								<div class="item-name">{{ $t('seller.customer.strategyText18') + $t('seller.customer.strategyText25') }}</div>
								<div class="item-text">{{ rategyData.operate_effect.pay_prices || '-'}}</div>
							</div>
						</div>
						<div class="effect-title" v-if="activeName ===1"><span class="name">{{ $t('seller.customer.strategyText26') }}</span></div>
						<div class="effect-list" v-if="activeName ===1 && rategyData.whole_operate_effect">
							<div class="item">
								<div class="item-name">{{ $t('seller.customer.strategyText19') }}</div>
								<div class="item-text">{{ rategyData.whole_operate_effect.visitors || '-'}}</div>
							</div>
							<div class="item">
								<div class="item-name">{{ $t('seller.customer.strategyText20') }}</div>
								<div class="item-text">{{ rategyData.whole_operate_effect.collect || '-'}}</div>
							</div>
							<div class="item">
								<div class="item-name">{{ $t('seller.customer.strategyText21') }}</div>
								<div class="item-text">{{ rategyData.whole_operate_effect.add_cart || '-'}}</div>
							</div>
							<div class="item">
								<div class="item-name">{{ $t('seller.customer.strategyText22') }}</div>
								<div class="item-text">{{ rategyData.whole_operate_effect.buyers || '-'}}</div>
							</div>
							<div class="item">
								<div class="item-name">{{ $t('seller.customer.strategyText23') }}</div>
								<div class="item-text">{{ rategyData.whole_operate_effect.prices || '-'}}</div>
							</div>
							<div class="item">
								<div class="item-name">{{ $t('seller.customer.strategyText24') }}</div>
								<div class="item-text">{{ rategyData.whole_operate_effect.pay_buyers || '-'}}</div>
							</div>
							<div class="item">
								<div class="item-name">{{ $t('seller.customer.strategyText25') }}</div>
								<div class="item-text">{{ rategyData.whole_operate_effect.pay_prices || '-'}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-card>
	</el-scrollbar>
</template>

<script>
import { fetchrRategyData } from "@/api/seller/customer"
export default {
	data() {
		var that = this
		return {
			rategyData: {},
			activeName: 1,
			datType: 1,
			datetimes: '',
			dayData: '',
			pickerOptions: {
				disabledDate(time) {
					if(that.rategyData &&  that.rategyData.create_time > 0) {
						return time.getTime() < that.rategyData.create_time * 1000
					} else {
						return time.getTime() < Date.now() - 86400000
					}
				},
				firstDayOfWeek: 1
			},
		}
	},
	created () {
		let start_time = parseInt(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate() -8 ).getTime() / 1000)
		let end_time = parseInt(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate() -1 ).getTime() / 1000)
		this.datetimes = [start_time,end_time]
		this.getData()
	},
	methods: {
		async getData() {
			const params = {
				type: this.activeName,
				operate_id: this.$route.params.id
			}
			if(this.datetimes) {
				params.time_from = this.datetimes[0]
				params.time_to = this.datetimes[1]
			}
			const { data } = await fetchrRategyData(params)
			this.rategyData = data
		},
		handleChangeType(val) {
			this.handleChangeTime(1)
		},
		handleChangeTime(val) {
			this.datType = val
			var start_time = ''
			var end_time = ''
			switch (val) {
				case 1:
					start_time = parseInt(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate() -8 ).getTime() / 1000)
					end_time = parseInt(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate() -1 ).getTime() / 1000)
					this.datetimes = [start_time,end_time]
					this.getData()
					break;
				case 2:
					start_time = parseInt(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate() -31 ).getTime() / 1000)
					end_time = parseInt(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate() -1 ).getTime() / 1000)
					this.datetimes = [start_time,end_time]
					this.getData()
					break;
				case 3:
					this.$refs.dayPicker.focus()
					break;
			}
		},
		changeDatePicker(val) {
			const today = new Date(val)
			var start_time = parseInt(new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime() / 1000)
			this.datetimes = [start_time,start_time]
			this.getData()
		}
	}
}
</script>

<style lang="scss" scoped>
.seller-card {
	.page-top {
		padding: 18px 24px;
		.title {
			color: #333333;
			font-size: 20px;
			line-height: 24px;
		}
		.text {
			margin-top: 12px;
			li {
				display: inline-block;
				color: #111;
				font-size: 12px;
				margin-right: 40px;
				span {
					color: #777;
					font-size: 14px;
					margin-right: 12px;
				}
			}
		}
		.type {
			margin-top: 24px;
			li {
				margin-bottom: 12px;
				span {
					margin-right: 6px;
				}
			}
		}
	}
	.page-tabs {
		margin-top: 24px;
		::v-deep .el-radio-group {
			.el-radio-button {
				.el-radio-button__inner {
					.el-tag {
						margin-left: 6px;
						border-color: transparent;
					}
				}
				&:first-child {
					.el-radio-button__inner {
						border-top-left-radius: 20px;
						border-bottom-left-radius: 20px;
					}
				}
				&:last-child {
					.el-radio-button__inner {
						border-top-right-radius: 20px;
						border-bottom-right-radius: 20px;
					}
				}
			}
		}
	}
	.page-section {
		border-radius: 6px;
		margin: 18px 0 24px;
		.section-head {
			height: 60px;
			padding: 0 24px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #f2f5fa;
			.title {
				font-size: 16px;
				font-weight: 700;
			}
			.tools {
				display: flex;
				align-items: center;
				li {
					margin-left: 12px;
					span {
						margin-right: 12px;
					}
				}
			}
			.tool-times {
			}
		}
		.section-wrap {
			padding: 0 24px;
		}
		.section-effect {
			padding: 24px 0;
			.effect-list {
				display: flex;
				flex-wrap: wrap;
				.item {
					flex-shrink: 0;
					overflow: hidden;
					width: calc((100% - 70px) / 8);
					padding: 20px 12px 15px;
					margin-right: 10px;
					box-sizing: border-box;
					.item-name {
						font-size: 14px;
						color: #b6b6b6;
						margin-bottom: 14px;
					}
					.item-text {
						font-size: 24px;
						color: #111;
						margin-bottom: 9px;
					}
				}
			}
			.effect-title {
				font-size: 14px;
				.name {
					color: #3a3a3a;
					font-size: 16px;
					font-weight: 700;
				}
			}
		}
	}
}
</style>